import React, { useCallback } from 'react';

const composeRefs = <T>(...refs: Array<React.Ref<T> | undefined>) => {
  return function setRefs(node: T) {
    refs.forEach((ref) => {
      if (!ref) {
        return;
      }

      if (typeof ref === 'function') {
        ref(node);
      } else {
        (ref as React.MutableRefObject<T>).current = node;
      }
    });
  };
};

/**
 * Hook to compose multiple refs
 * Accepts callback refs and RefObject(s)
 */
export const useComposedRefs = <T>(
  ...refs: Array<React.Ref<T> | undefined>
): React.RefCallback<T> => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(composeRefs(...refs), refs);
};
